import React from "react"

import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import SecondaryBanner from "../containers/SecondaryBanner"
import { ContentWrapper } from "../containers/alpacked.style"

const ThankYouPage = ({ location }) => {
  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="Thank You"
        description="Thank you for your submission! Your ebook will be sent to your indox shortly."
      />
      <Layout>
        <ContentWrapper>
          <SecondaryBanner
            title="Thank you!"
            subtitle="Thank you for your submission! Your ebook will be sent to your indox shortly."
            buttonText="BACK TO WEBSITE"
            thankYouImg
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default ThankYouPage
