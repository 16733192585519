import React from "react"
import { Link } from "gatsby"
import Text from "../../components/Text"
import Image from "../../components/Image"
import Button from "../../components/Button"
import Heading from "../../components/Heading"
import Container from "../../components/UI/Container"
import BannerWrapper, {
  BannerContent,
  BannerImage,
  ButtonGroup,
  Overlay,
} from "./secondaryBanner.style"

import bannerImg from "../../common/assets/image/alpacked/404/404.png"

const SecondaryBanner = ({ title, subtitle, buttonText, thankYouImg }) => {
  return (
    <BannerWrapper thankYouImg={thankYouImg}>
      <Overlay thankYouImg={thankYouImg}>
        <Container width="1220px">
          <BannerContent thankYouImg={thankYouImg}>
            <Heading as="h1" content={title} />
            <Text content={subtitle} />
            <ButtonGroup thankYouImg={thankYouImg}>
              <Link to="/">
                <Button className="primary" title={buttonText} />
              </Link>
            </ButtonGroup>
          </BannerContent>
          {!thankYouImg && (
            <BannerImage>
              <Image src={bannerImg} alt="Banner" />
            </BannerImage>
          )}
        </Container>
      </Overlay>
    </BannerWrapper>
  )
}

export default SecondaryBanner
