import bannerImg from "../../common/assets/image/alpacked/404/404.jpg"
import thankYouImg from "../../common/assets/image/alpacked/thankyou/thank-you.png"
import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const BannerWrapper = styled.div`
  margin-top: 70px;
  min-height: 802px;
  overflow: hidden;
  background-image: ${props =>
    props.thankYouImg ? `url(${thankYouImg})` : `url(${bannerImg})`};
  background-color: ${themeGet("colors.black")};
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  min-height: 92vh;

  @media only screen and (max-width: 1260px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: 1099px) {
    min-height: auto;
  }
`

export const BannerContent = styled.div`
  max-width: ${props => (props.thankYouImg ? `800px` : `500px`)};
  width: 100%;
  margin-top: ${props => (props.thankYouImg ? `0px` : `0px`)};
  @media only screen and (max-width: 990px) {
    margin-top: 0px;
  }

  @media only screen and (max-width: 991px) {
    /* flex-shrink: 0; */
    max-width: ${props => (props.thankYouImg ? "600px" : "360px")};
  }

  h1 {
    font-size: 70px;
    font-weight: 700;
    color: ${themeGet("colors.white")};
    @media only screen and (max-width: 990px) {
      font-size: 62px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 50px;
    }
  }

  p {
    max-width: ${props => (props.thankYouImg ? `800px` : `400px`)};
    color: ${themeGet("colors.white")};
    font-size: 28px;
    line-height: 36px;
    @media only screen and (max-width: 990px) {
      margin-top: 20px;
      font-size: 24px;
      line-height: 36px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
`

export const BannerImage = styled.div`
  flex-shrink: 0;
  align-self: center;
  @media only screen and (max-width: 1370px) {
    img {
      width: 450px;
      margin: 0 auto 40px;
    }
  }
  @media only screen and (max-width: 480px) {
    margin-top: 40px;
    img {
      max-width: 70%;
      margin: 0 auto 40px;
    }
  }
`

export const ButtonGroup = styled.div`
  margin-top: 60px;

  .reusecore__button {
    width: ${props => (props.thankYouImg ? `532px` : `auto`)};
    text-transform: inherit;
    border-radius: 0px;
    padding: 22px 65px;
    font-size: 20px;
    background-color: ${props => (props.thankYouImg ? `white` : `transparent`)};
    color: ${props => (props.thankYouImg ? `#21AAD4` : `white`)};
    border: 1px solid ${themeGet("colors.white")};
    @media only screen and (max-width: 990px) {
      width: auto;
      padding: 22px 22px;
      width: 100%;
      font-size: 18px;
    }
    &.primary {
      &:hover {
        background-color: ${props => (props.thankYouImg ? `#e9f6fa` : `white`)};
        border: 1px solid ${themeGet("colors.white")};
        color: ${props => (props.thankYouImg ? `#21AAD4` : `#21AAD4`)};
      }
    }
  }
`
export const Overlay = styled.div`
  width: 100%;
  min-height: 92vh;
  background-color: ${props => (props.thankYouImg ? `` : `rgba(0, 0, 0, 0.7)`)};
  > div.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 92vh;
    @media only screen and (max-width: 1370px) {
      flex-wrap: wrap-reverse;
      justify-content: ${props => (props.thankYouImg ? "left" : "center")};
      align-content: ${props => (props.thankYouImg ? "left" : "center")};
    }
  }
`

export default BannerWrapper
